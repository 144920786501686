import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import type { Company } from "~/lib/ecosystem";
import { allCategories } from "~/routes/ecosystem";

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      ease: "easeInOut",
    },
  },
};

const item = {
  hidden: { x: 25, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export function EcosystemCarousel(): JSX.Element {
  const [shuffledCategories, setShuffledCategories] = useState<Company[]>([]);

  useEffect(() => {
    shuffleCategories();
  }, []);

  const shuffleCategories = () => {
    const shuffled = [...allCategories].sort(() => Math.random() - 0.5);
    setShuffledCategories(shuffled);
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      className="h-16 md:h-24 w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
    >
      <div className="flex items-center justify-start gap-4 md:gap-6 max-w-none animate-ecosystem-scroll">
        {shuffledCategories.map((company: Company) => (
          <motion.img
            variants={item}
            key={company.name}
            className="h-16 w-16 md:h-24 md:w-24 my-0 rounded-full border border-primary flex"
            src={company.icon}
            alt={company.name}
          />
        ))}
      </div>
    </motion.div>
  );
}
