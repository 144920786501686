import { faArrowRight, faArrowUpRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/cloudflare";
import { motion } from "framer-motion";
import { useState } from "react";

import { BlockchainIcon } from "~/components/logos/blockchain";
import { CTAPrimary } from "~/components/site/call-to-action-primary";
import { EcosystemCarousel } from "~/components/site/carousel";
import { ExternalLink } from "~/components/site/external-link";
import { item, Layout } from "~/components/site/layout";
import { BG_BASE_COLORS } from "~/lib/colors";
import { ROUTE_DATA } from "~/lib/constants";
import type { Blockchain, Company, Stablecoin, Wallet } from "~/lib/ecosystem";
import {
  BLOCKCHAINS,
  CUSTODIANS,
  EXCHANGES,
  LENDERS,
  LIQUIDITY,
  PAYMENTS,
  RAAS,
  STABLECOINS,
  WALLETS,
} from "~/lib/ecosystem";
import { formatUrl } from "~/lib/format-url";
import { getMeta } from "~/lib/get-meta";
import { getUrls } from "~/lib/get-urls";

const { desc, path, title, ogImage } = ROUTE_DATA.ecosystem;

export function loader({ request }: LoaderFunctionArgs) {
  return {
    urls: getUrls({ path, request }),
  };
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return data
    ? [
        ...getMeta({ desc, title, urls: data.urls }),
        { property: "og:image", content: `${data.urls.base}${ogImage}` },
        { name: "twitter:image", content: `${data.urls.base}${ogImage}` },
      ]
    : [];
};

export const allCategories = [
  ...STABLECOINS,
  ...BLOCKCHAINS,
  ...WALLETS,
  ...EXCHANGES,
  ...PAYMENTS,
  ...LIQUIDITY,
  ...CUSTODIANS,
  ...LENDERS,
  ...RAAS,
];

const tabs = {
  All: allCategories,
  Stablecoin: STABLECOINS,
  Blockchain: BLOCKCHAINS,
  Wallet: WALLETS,
  Exchange: EXCHANGES,
  Payments: PAYMENTS,
  Liquidity: LIQUIDITY,
  Custody: CUSTODIANS,
  Lending: LENDERS,
  RaaS: RAAS,
};

export default function Ecosystem(): JSX.Element {
  const [activeTab, setActiveTab] = useState("All");

  const handleClick = (category: string) => {
    setActiveTab(category);
  };

  const categoryColors: Record<string, string> = {};
  Object.keys(tabs).forEach((category, i) => {
    categoryColors[category] = BG_BASE_COLORS[i % BG_BASE_COLORS.length];
  });

  return (
    <Layout>
      <section className="section-first">
        <div className="container">
          <motion.div variants={item} className="max-w-3xl">
            <h1 className="eyebrow">Ecosystem</h1>
            <h2 className="mt-0 mb-6">Our network of trusted partners</h2>
            <p className="subheading mt-2 mb-16">
              Brale is integrated with a global ecosystem of blockchains,
              wallets, exchanges, and applications to support bespoke
              fiat-backed stablecoins.
            </p>
          </motion.div>
          <motion.div variants={item}>
            <EcosystemCarousel />
          </motion.div>
        </div>
      </section>
      <section className="section-sub">
        <div className="container">
          <TabGroup>
            <motion.div variants={item}>
              <TabList className="grid grid-cols-2 xs:grid-cols-3 sm:!grid-cols-4 md:!grid-cols-5 xl:flex flex-grow xl:flex-row flex-wrap mb-8 text-sm not-prose gap-2 md:gap-4">
                {Object.keys(tabs).map((category) => (
                  <Tab
                    key={category}
                    className={`btn nav flex gap-2 !justify-start ${
                      activeTab === category ? "active" : ""
                    }`}
                    onClick={() => handleClick(category)}
                  >
                    {category !== "All" && (
                      <div
                        className={`rounded-full h-2 w-2 ${categoryColors[category]}`}
                      />
                    )}
                    {category}
                  </Tab>
                ))}
              </TabList>
            </motion.div>
            <TabPanels className="mt-2">
              {Object.values(tabs).map((category, i) => (
                <motion.div variants={item} key={i}>
                  <TabPanel className="flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 sm:gap-8 max-w-none">
                    {category
                      .sort((a, b) => a.id.localeCompare(b.id))
                      .map(
                        (company: Company | Blockchain | Stablecoin | Wallet) =>
                          isStablecoin(company) ? (
                            <a
                              key={company.id}
                              className="bg-secondary round border border-primary global-hover-transition hover:border-gray-400 hover:dark:border-gray-600 flex flex-col no-underline relative group"
                              href={company.url}
                            >
                              <div className="flex items-center justify-between border-b border-primary pl-6 pr-2 py-2">
                                <div className="flex items-center gap-2 ">
                                  <div
                                    className={`rounded-full h-2 w-2 ${
                                      categoryColors[
                                        company.category?.toString() || ""
                                      ]
                                    }`}
                                  />
                                  <span className="text-sm prose dark:prose-invert">
                                    {company.category}
                                  </span>
                                </div>
                                <span className="p-2 border border-primary rounded-full opacity-0 group-hover:opacity-100 group-hover:translate-x-[-10px] relative -right-[10px] transition-all duration-500 leading-none">
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="h-4 w-4"
                                  />
                                </span>
                              </div>
                              <div className="flex flex-col flex-grow gap-4 p-6">
                                <div className="flex flex-col gap-4 flex-grow">
                                  <div className="flex items-center gap-x-3">
                                    <img
                                      className="h-12 w-12 my-0 rounded-full border border-primary"
                                      src={company.icon}
                                      alt={company.name}
                                    />
                                    <div className="flex flex-col gap-0">
                                      <h4 className="h5 m-0">
                                        {company.ticker}
                                      </h4>
                                      <span className="text-sm prose dark:prose-invert">
                                        {company.name}
                                      </span>
                                    </div>
                                  </div>
                                  <p className="!text-sm text-secondary font-normal m-0">
                                    {company.desc}
                                  </p>
                                </div>
                              </div>
                            </a>
                          ) : (
                            <ExternalLink
                              key={company.id}
                              className="bg-secondary round border border-primary global-hover-transition hover:border-gray-400 hover:dark:border-gray-600 flex flex-col no-underline relative group"
                              href={company.url}
                            >
                              <div className="flex items-center justify-between border-b border-primary pl-6 pr-2 py-2">
                                <div className="flex items-center gap-2">
                                  <div
                                    className={`rounded-full h-2 w-2 ${
                                      categoryColors[
                                        company.category?.toString() || ""
                                      ]
                                    }`}
                                  />
                                  <span className="text-sm prose dark:prose-invert">
                                    {company.category}
                                  </span>
                                </div>
                                <span className="p-2 border border-primary rounded-full opacity-0 group-hover:opacity-100 group-hover:translate-x-[-10px] relative -right-[10px] transition-all duration-500 leading-none">
                                  <FontAwesomeIcon
                                    icon={faArrowUpRight}
                                    className="h-4 w-4"
                                  />
                                </span>
                              </div>
                              <div className="flex flex-col flex-grow gap-4 p-6">
                                <div className="flex flex-col gap-4 flex-grow">
                                  <div className="flex items-center gap-x-3">
                                    {isBlockchain(company) ? (
                                      <BlockchainIcon
                                        key={i}
                                        chainId={company.chainId}
                                        className="!h-12 !w-12 my-0 rounded-full border border-primary"
                                      />
                                    ) : (
                                      <img
                                        className="h-12 w-12 my-0 rounded-full border border-primary"
                                        src={company.icon}
                                        alt={company.name}
                                      />
                                    )}
                                    <div className="flex flex-col gap-0">
                                      <h4 className="h5 m-0">{company.name}</h4>
                                      <span className="text-sm prose dark:prose-invert">
                                        {formatUrl(company.url)}
                                      </span>
                                    </div>
                                  </div>
                                  <p className="!text-sm text-secondary font-normal m-0">
                                    {company.desc}
                                  </p>
                                </div>
                                {isWallet(company) && (
                                  <div className="flex gap-1">
                                    {company.chains.map((c, i) => (
                                      <BlockchainIcon
                                        key={i}
                                        chainId={c}
                                        className="!h-5 !w-5"
                                      />
                                    ))}
                                  </div>
                                )}
                              </div>
                            </ExternalLink>
                          )
                      )}
                  </TabPanel>
                </motion.div>
              ))}
            </TabPanels>
          </TabGroup>
        </div>
      </section>
      <motion.div
        variants={item}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <CTAPrimary
          heading="Join the ecosystem"
          subheading="Integrate SBC, USDGLO, and all Brale issued stablecoins into your
              application."
          primaryBtn="Apply"
          primaryBtnUrl="https://docs.google.com/forms/d/e/1FAIpQLSf5vjD5sloDPBXKzjdWziOmOZ6MMvxcZOqA8nWIYQmHPo2seA/viewform"
          primaryExternal={true}
        />
      </motion.div>
    </Layout>
  );
}

function isStablecoin(
  company: Company | Blockchain | Stablecoin | Wallet
): company is Stablecoin {
  return "ticker" in company;
}

function isBlockchain(
  company: Company | Blockchain | Stablecoin | Wallet
): company is Blockchain {
  return "chainId" in company;
}

function isWallet(
  company: Company | Blockchain | Stablecoin | Wallet
): company is Wallet {
  return (
    "chains" in company && "support" in company && "recognition" in company
  );
}
